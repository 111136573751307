import { BigMapTzKt, MissionConfig, MissionData, WithKey } from "@/type"
import { getEndMissionDate } from "@/utils/missionHelper"
import { addKeyToBigMap } from "@/utils/utilities"
import { Config } from "config/config_type"
import { getBigMap, getBigMapsOfContract } from "./tzktApi"

export async function getMissionData (config: Config, currentBlock: number | null, trainingRoomAddress: string): Promise<{missionData: Array<WithKey<MissionData>>, missionList: Array<WithKey<MissionConfig>>}> {
    const bigMaps: Array<BigMapTzKt> = await getBigMapsOfContract(config.networkTzkt, trainingRoomAddress, 300)
    const missionDataBigMapId = bigMaps.find(item => item.path === 'mission_data')?.ptr
    if (!missionDataBigMapId) {
        throw Error('Can not find mission_data big map id')
    }

    const missionConfigBigMapId = bigMaps.find(item => item.path === 'mission_config')?.ptr
    if (!missionConfigBigMapId) {
        throw Error('Can not find mission_config big map id')
    }

    const missionDataBigMap = await getBigMap<MissionData>(config.networkTzkt, missionDataBigMapId, 300)
    const missionData = missionDataBigMap.map(item => addKeyToBigMap(item))
    const stoppedMissionKey = missionData.filter(item => item.stop).map(item => item.key)
    const missionConfigBigMap = await getBigMap<MissionConfig>(config.networkTzkt, missionConfigBigMapId, 300)
    const missionList = missionConfigBigMap.map(item => addKeyToBigMap(item)).filter(item => !stoppedMissionKey.includes(item.key))

    missionList.forEach((item) => {
        item.end_mission = getEndMissionDate(config.timeBlock, currentBlock, item)
    })
    return {
        missionData: missionData,
        missionList: missionList
    }
}