









import { Component, Prop, Vue } from 'vue-property-decorator';
import { Family } from '@/type';

@Component({
    components: {}
})
export default class FamilyImg extends Vue {
    @Prop({required: true})
    family!: Family

    @Prop({default: null})
    height!: string

    getStyle () {
        if (!this.height) {
            return null
        }
        return `height: ${this.height}`
    }
}
