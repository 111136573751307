





































































































import { Family, JsonMission, MissionConfig, MissionData, TrooperzAttributes, WithKey } from '@/type';
import { calcBonusTcoin, countdown, getBonuxTcoinForLvl, getIpfsPath } from '@/utils/utilities';
import { checkMissionRequirements, getInforRewardsTcoin, getJsonMission, getRoiByHours, isMissionEndedDate, tcoinByBlockToByHours, getFailedMissionRequirements } from '@/utils/missionHelper';
import axios from 'axios';
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { convertValueFromContract, getLvlWithXp } from '@/utils/utilities';
import RewardsIcon from '@/components/RewardsIcon.vue';
import { Optional } from '@airgap/beacon-transport-walletconnect/node_modules/@airgap/beacon-types'
import _ from 'lodash';

@Component({
    components: {
        RewardsIcon
    }
})
export default class ChooseMissionDialog extends Vue {
    @Prop()
    missionList!: Array<WithKey<MissionConfig>>

    @Prop()
    missionData!: Array<WithKey<MissionData>>


    @Prop()
    xp!: number

    @Prop()
    isInOldConctact!: boolean

    @Prop()
    currentMissionId!: number

    @Prop()
    trooperzId!: number

    @Prop()
    trooperzAttributes!: TrooperzAttributes

    @PropSync('active', {type: Boolean})
    activeDialog!: boolean

    @Prop()
    currentBlock!: number
    
    @PropSync('jsonMissionDictSync', {default: () => {return {}}})
    jsonMissionDict!: Record<string, JsonMission>
    loadingMissionJson = false

    cachedMissionDict: Record<string, JsonMission> = {}
    

    /**
     * in mode all, only base rewards are displayed are are not computed based on a trooperz
     * trooperzId and xp params are not mandatory in modeAll
     */
    @Prop({default: false})
    modeAll!: boolean

    missionCountdown: {[key: string]: string | null} = {}

    allMission = true

    activeMission = true

    mounted () {
        this.launchIntervalCountdownMission()
    }

    getImgMission (missionKey: string) {
        return getIpfsPath(this.jsonMissionDict[missionKey].displayUri)
    }

    getDescriptionMission (missionKey: string) {
        return this.jsonMissionDict[missionKey].description
    }

    getTitleMission (missionKey: string) {
        return this.jsonMissionDict[missionKey].title
    }

    isMissionDisabled (mission: WithKey<MissionConfig>) {
        if (this.modeAll) {
            return false
        }
        return this.missionIsCurrentMission(mission) || !this.checkRequirements(mission) || this.isMissionEnded(mission)
    }

    isMissionEnded (mission: MissionConfig) {
        return mission.end_mission && mission.end_mission.getTime() < new Date().getTime()
    }

    getTooltipTextMissionEnded (mission: WithKey<MissionConfig>) {
        if (this.isMissionEnded(mission)) {
            return 'This mission is complete. New missions are or will soon be available!'
        }
        return `
            The current mission cycle ends in ${this.missionCountdown[mission.key]}. 
            A new cycle will begin with new missions and new requirements soon after the current cycle ends.
        `
    }

    closeDialog () {
        this.activeDialog = false
        this.$emit('closeDialog')
    }

    emitGoToMission (missionKey: string) {
        this.$emit('goToMission', missionKey)
    }

    getConfig () {
        if (!this.$config) {
            throw Error('No config!')
        }
        return this.$config
    }
   /**
    *  is a Trooperz unique ? 
     * return always false if modeAll
     */
    isUnique () {
        if (this.modeAll) {
            return false
        }
        const config = this.$config
        return config && config.uniqueTrooperz.includes(Number(this.trooperzId))
    }
    /**
     * return always 0 if modeAll
     */
    getXp (): number {
        return this.modeAll ? 0 : this.xp
    }

    displayRewards(tcoinByBlock: any) {
        let config = this.getConfig()
        return getRoiByHours(Number(tcoinByBlock), 
                            config.timeBlock, 
                            this.getXp(), 
                            config.nbDigitTcoin,
                            this.isUnique())
    }

    getInfoRewards(tcoinByBlock: any) {
        let config = this.getConfig()
        return getInforRewardsTcoin(Number(tcoinByBlock), this.getXp(), config.nbDigitTcoin, this.isUnique())
    }

    checkRequirements (mission: MissionConfig) {
        if (this.modeAll) {
            return true
        }
        return checkMissionRequirements(this.getLevel(this.xp), this.trooperzAttributes, mission)
    }

    getFailedRequirements (mission: MissionConfig) {
        return getFailedMissionRequirements(this.getLevel(this.xp), this.trooperzAttributes, mission)
    }

    checkLevel (min_lvl?: string | null) {
        if (!min_lvl) {
            return true
        }
        return Number(min_lvl) <= this.getLevel(this.xp)
    }

    checkFamily (family: Family, allowedFamily: Family[] | null) {
        if (!allowedFamily) {
            return true
        }
        return family === 'tcorp' || allowedFamily.includes(family)
    }

    checkStats (trooperzStats: number, stats?: string | null) {
        if (!stats) {
            return true
        }
        return Number(stats) <= trooperzStats
    }

    getEndMission (mission: MissionConfig) {
        if (mission.end_mission) {
            let distance = mission.end_mission.getTime() - (new Date().getTime())
            return distance <=  0 ? null : countdown(distance)
        }
        return null
    }


    launchIntervalCountdownMission () {
        window.setInterval(() => {
            this.missionList.forEach((item) => {
                const res = this.getEndMission(item)
                Vue.set(this.missionCountdown, item.key, res ? res : 'Mission ended')
            })
        })
    }

    getLevel (xp: number) {
        return getLvlWithXp(xp)
    }

    missionIsCurrentMission (mission: WithKey<MissionConfig>) {
        return this.currentMissionId === Number(mission.key) && !this.isInOldConctact
    }

    missionReqFilter (mission: MissionConfig): Partial<MissionConfig> {
        let req: Partial<MissionConfig> = {}
        let reqKeys: Array<keyof MissionConfig> = ['min_lvl', 'min_hacking', 'min_trickery', 'min_fire_arms', 'min_close_combat']
        for (let key in mission) {
            let keyTyped = key as keyof MissionConfig

            if (mission[keyTyped] !== null && mission[keyTyped] !== undefined 
            && reqKeys.includes(keyTyped)) {
                req[keyTyped] = mission[keyTyped] as any
            }
        }
        return req
    }

    strKeysReq (key: string) {
        const tab = key.split('_')
        return `${tab[0].toUpperCase()} ${tab[1]}`
    }

    get missionListFiltered () {
        let mission = _.cloneDeep(this.missionList)

        // remove future mission
        mission = mission.filter(item => !this.currentBlock || Number(item.start_block) < this.currentBlock)

        if (!this.allMission) {
            mission = mission.filter(item => this.checkRequirements(item))
        }
        

        if (this.activeMission) {
            mission = mission.filter(item => {
                return !isMissionEndedDate(item)
            })
        } else {
            mission = mission.filter(item => {
                return isMissionEndedDate(item)
            })
        }
        return mission
    }

    get textAllMission () {
        if (this.allMission) {
            return 'All mission'
        }
        return 'Available mission'
    }

    get textActiveMission () {
        if (this.activeMission) {
            return 'Active'
        }
        return 'Inactive'
    }

    async loadMission (missionConfig: WithKey<MissionConfig>) {
        let jsonMission: JsonMission
        if (this.cachedMissionDict[missionConfig.ipfs_link]) {
            jsonMission = this.cachedMissionDict[missionConfig.ipfs_link]
        } else {
            jsonMission = await getJsonMission(missionConfig.ipfs_link)
            this.cachedMissionDict[missionConfig.ipfs_link] = jsonMission
        }
        this.$nextTick(() => {
            Vue.set(this.jsonMissionDict, missionConfig.key, jsonMission)
        })
    }

    @Watch('missionList', {immediate: true  })
    async onChangeMissionData () {
        this.loadingMissionJson = true
        for (let mission of this.missionList) {
            await this.loadMission(mission)
        }
        this.loadingMissionJson = false
    }
}
